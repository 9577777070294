import { useEffect, useState } from "react";
import { BackArrow, SaveIcon } from "allIcons";
import {
  BodyContainer,
  TextEditor,
  HeaderContainer,
  IconWrapper,
  InputField,
  ListCard,
  PreviewCard,
  PrimaryHeading,
  RightMainContainer,
  SecondaryHeading,
  ListIconWrapper,
  AddComponent,
  MainButton,
  PopOver,
  AddFromTemplateLibrary,
  DropDownCard,
  Loader,
  DeleteButton,
} from "commonUI";
import { useParams } from "react-router-dom";
import { useContentRequestsItem } from "api/templateLibrary/contentRequestsItem";
import { contentRequestItemType, isEmpty, parse, useUtilities } from "helpers";
import { useTags } from "api/siteSettings/Tags";
import {
  useAddContentRequest,
  useContentRequests,
  useDeleteContentRequest,
  useUpdateContentRequest,
} from "api/templateLibrary/contentRequests";
import { updateFormState } from "redux/reducers/templateLibrary/contentRequests";
import { useUserPermissions } from "hooks/user";

export default function AddEditContentRequestTemplate() {
  const {
    contentRequests: { form },
    navigate,
  } = useUtilities();
  const { id } = useParams();
  const { notification, dispatch } = useUtilities();
  const { data: contentRequestItem, isLoading: loadingContentRequestItem } =
    useContentRequestsItem();
  const { data: contentRequests, isLoading: loadingContentRequests } =
    useContentRequests();
  const { mutate: addContentRequest, isLoading: isAdding } =
    useAddContentRequest();
  const { mutate: updateContentRequest, isLoading: isUpdating } =
    useUpdateContentRequest();
  const { mutate: deleteContentRequest, isLoading: isDeleting } =
    useDeleteContentRequest(true);
  const { data: tagsList } = useTags();
  const { add_new_templates, edit_templates, delete_templates } =
    useUserPermissions();

  const [inputValues, setInputValues] = useState(
    isEmpty(form)
      ? {
          title: "",
          message: "",
          contentitems: [],
          tags: [],
        }
      : form
  );
  const [openAddContentItemModal, setOpenAddContentItemModal] = useState(false);
  const [openAddTagModal, setOpenAddTagModal] = useState(false);

  useEffect(() => {
    if (
      id === "add" ||
      loadingContentRequests ||
      loadingContentRequestItem ||
      contentRequests.length === 0
    )
      return;
    const contentRequest = contentRequests.find(
      (ele) => String(ele.id) === String(id)
    );
    if (contentRequest) {
      setInputValues({
        title: contentRequest.title,
        message: contentRequest.message,
        tags: parse(contentRequest.tags),
        contentitems: parse(contentRequest.contentitems).filter((_) => !!_),
      });
    }
  }, [
    contentRequests,
    loadingContentRequests,
    contentRequestItem,
    id,
    loadingContentRequestItem,
  ]);
  const hanldeSave = () => {
    if (inputValues.title === "") {
      notification({
        type: "error",
        message: "please enter title",
      });
      return;
    }
    if (id === "add" && add_new_templates) {
      addContentRequest({
        ...inputValues,
      });
      return;
    }
    if (edit_templates)
      updateContentRequest({
        ...inputValues,
        id,
      });
  };

  return (
    <>
      <RightMainContainer>
        {isAdding || isUpdating || isDeleting ? <Loader loader="block" /> : ""}
        <HeaderContainer>
          <IconWrapper>
            <BackArrow
              onClick={() =>
                navigate("/template-library/content-request-templates")
              }
            />
            <PrimaryHeading className="clamp-text">
              {id === "add" ? "Add Content Request" : inputValues.title}
            </PrimaryHeading>
          </IconWrapper>
          <div className="flex items-center justify-center gap-14">
            <DeleteButton
              show={id !== "add" && delete_templates}
              deleteTitle="Delete This Template?"
              deleteMessage="You are about to delete this template. It will be permanently removed from your account library. Are you sure you want to do this?"
              deleteButtonText="Confirm Delete"
              onDelete={() => deleteContentRequest(id)}
            />
            {add_new_templates || edit_templates ? (
              <MainButton onClick={hanldeSave}>
                <SaveIcon />
                Save Changes
              </MainButton>
            ) : (
              ""
            )}
          </div>
        </HeaderContainer>
        <BodyContainer>
          <InputField
            label="Title"
            placeholder="Write Title Here"
            value={inputValues.title}
            setValue={(value) =>
              setInputValues((pre) => ({ ...pre, title: value }))
            }
          />
          <TextEditor
            label="Description"
            editorClass="mt-2"
            option={false}
            rows={5}
            value={inputValues.message}
            setValue={(value) =>
              setInputValues((pre) => ({ ...pre, message: value }))
            }
            reRender={id !== "add"}
          />
          <div className="mt-3">
            <SecondaryHeading>Content Items</SecondaryHeading>
            {inputValues.contentitems.map((item, index) => {
              return (
                <ListCard
                  key={index}
                  data={item.title}
                  edit={true}
                  editLink={`/template-library/content-request-items/${item.id}`}
                  onEditClick={() => {
                    dispatch(updateFormState({ ...inputValues, id }));
                  }}
                  crossIcon
                  showModalonCross
                  deleteTitle="Remove This Item?"
                  deleteMessage="You are about to remove this item from your template. Are you sure you want to do this?"
                  deleteButtonText="Confirm"
                  onCross={() => {
                    setInputValues((pre) => ({
                      ...pre,
                      contentitems: pre.contentitems.filter(
                        (_) => _.id !== item.id
                      ),
                    }));
                  }}
                  icon={
                    <ListIconWrapper>
                      {contentRequestItemType[item.hint || item.type]}
                    </ListIconWrapper>
                  }
                  deleteIcon={true}
                />
              );
            })}
          </div>
          <AddComponent
            title={"Add Content"}
            className="mt-3 mb-3"
            value={openAddContentItemModal}
            onClick={() => setOpenAddContentItemModal(true)}
          >
            <PopOver
              open={openAddContentItemModal}
              onClose={() => setOpenAddContentItemModal(false)}
              style={{
                width: "300px",
                top: "unset",
                left: "unset",
                right: "unset",
                bottom: "calc(100% + 15px)",
              }}
              toolTip={{ horizontal: "center", vertical: "bottom" }}
            >
              <AddFromTemplateLibrary
                heading="Add from Content Request Items"
                data={contentRequestItem
                  .filter(
                    (_) =>
                      inputValues.contentitems.findIndex(
                        (ele) => ele.id === _.id
                      ) === -1
                  )
                  .map((item) => ({
                    ...item,
                    onClick: () => {
                      setInputValues((pre) => ({
                        ...pre,
                        contentitems: [...pre.contentitems, item],
                      }));
                    },
                    icon: contentRequestItemType[item.hint || item.type],
                  }))}
                isLoading={loadingContentRequestItem}
              />
            </PopOver>
          </AddComponent>
          <div className="mt-2">
            <SecondaryHeading>Tags</SecondaryHeading>
            <div className="flex items-center gap-5">
              {inputValues.tags.map((item, index) => (
                <PreviewCard
                  key={index}
                  color={item.color}
                  title={item.text}
                  icon={true}
                  className="m-0"
                  onCross={() =>
                    setInputValues((pre) => ({
                      ...pre,
                      tags: pre.tags.filter((_) => _.id !== item.id),
                    }))
                  }
                />
              ))}
            </div>
          </div>
          <AddComponent
            value={openAddTagModal}
            onClick={() => setOpenAddTagModal(true)}
          >
            <PopOver
              open={openAddTagModal}
              onClose={() => setOpenAddTagModal(false)}
              style={{
                top: "unset",
                right: "unset",
                left: "unset",
                bottom: "calc(100% + 15px",
              }}
              toolTip={{ horizontal: "center", vertical: "bottom" }}
            >
              <DropDownCard
                title="Add Tag"
                listStyle
                list={tagsList.map((item) => ({
                  ...item,
                  text: item.tag_title,
                  index: item.id,
                }))}
                onSelect={(value) => {
                  setInputValues((pre) => ({
                    ...pre,
                    tags: [...pre.tags, value],
                  }));
                }}
                selectedValues={inputValues.tags.map((item) => item.id)}
              />
            </PopOver>
          </AddComponent>
        </BodyContainer>
      </RightMainContainer>
    </>
  );
}
