import { createSlice } from "@reduxjs/toolkit";
import { isEqual } from "helpers";

const initialState = {
  companies: [],
  loaded: false,
  form: {},
};

const companiesSlice = createSlice({
  name: "companies",
  initialState,
  reducers: {
    getAllCompanies: (state, action) => {
      state.companies = action.payload;
      state.loaded = true;
    },
    addSingleCompany: (state, action) => {
      state.companies.push(action.payload);
      state.form = {};
    },
    updateSingleCompany: (state, action) => {
      const index = state.companies.findIndex((company) =>
        isEqual(company.id, action.payload.company_id || action.payload.id)
      );
      if (index !== -1) {
        for (const key in action.payload) {
          if (Object.hasOwnProperty.call(state.companies[index], key)) {
            state.companies[index][key] = action.payload[key];
          }
        }
      }
      state.form = {};
    },
    deleteSingleCompany: (state, action) => {
      state.companies = state.companies.filter(
        (company) => !isEqual(company.id, action.payload)
      );
      state.form = {};
    },
    updateform: (state, action) => {
      state.form = action.payload;
    },
    unloadCompanies: (state) => {
      state.loaded = false;
    },
  },
});

export const {
  getAllCompanies,
  addSingleCompany,
  updateSingleCompany,
  deleteSingleCompany,
  updateform,
  unloadCompanies,
} = companiesSlice.actions;

export default companiesSlice.reducer;
