import { createSlice } from "@reduxjs/toolkit";
import { isEqual } from "helpers";

const initialState = {
  siteTemplate: {
    approvals: [],
    loaded: false,
  },
  approvals: [],
  loaded: false,
  website_id: "",
};

const approvalsSlice = createSlice({
  name: "approvals",
  initialState,
  reducers: {
    setApprovalsAction(state, action) {
      const { website_id, approvals } = action.payload;
      if (website_id) {
        state.approvals = approvals;
        state.loaded = true;
        state.website_id = website_id;
      } else {
        state.siteTemplate.approvals = approvals;
        state.siteTemplate.loaded = true;
      }
    },
    updateApprovalAction(state, action) {
      if (action.payload.website_id) {
        const index = state.approvals.findIndex((_) =>
          isEqual(_.id, action.payload.id)
        );
        if (index !== -1) {
          for (const key in action.payload) {
            state.approvals[index][key] = action.payload[key];
          }
        }
      } else {
        const index = state.siteTemplate.approvals.findIndex((_) =>
          isEqual(_.id, action.payload.id)
        );
        if (index !== -1) {
          for (const key in action.payload) {
            state.siteTemplate.approvals[index][key] = action.payload[key];
          }
        }
      }
    },
  },
});

export const { setApprovalsAction, updateApprovalAction } =
  approvalsSlice.actions;

export default approvalsSlice.reducer;
