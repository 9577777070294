import {
  CheckListIcon,
  ContractIcon,
  CourseIcon,
  FilesIcon,
  FormFieldIcon,
  FormFieldOptionIcon,
  FormSectionIcon,
  FormTemplateIcon,
  GlossaryIcon,
  NotesIcon,
  PhaseIcon,
  PluginIcon,
  PluginListIcon,
  ProposalIcon,
  ScreenRecordingIcon,
  ServiceIcon,
  TaskIcon,
  TutorialIcon,
} from "allIcons";
import { compact } from "helpers";

export default function libraryMenu(siteTemplate) {
  const menu = [
    {
      title: "Tasks",
      module: "tasks",
      icon: <TaskIcon />,
    },
    {
      title: "Checklists",
      module: "checklists",
      icon: <CheckListIcon />,
    },
    {
      title: "Notes",
      module: "notes",
      icon: <NotesIcon />,
    },
    !siteTemplate && {
      title: "Phases",
      module: "phases",
      icon: <PhaseIcon />,
    },
    {
      title: "Glossary",
      module: "glossary",
      icon: <GlossaryIcon />,
    },
    {
      title: "Tutorials",
      module: "tutorials",
      icon: <TutorialIcon />,
    },
    // Here for future use
    // {
    //   title: "Courses",
    //   module: "courses",
    //   icon: <CourseIcon />,
    // },
    {
      title: "Content Requests",
      module: "content-request-templates",
      icon: <FilesIcon />,
    },
    {
      title: "Content Request Items",
      module: "content-request-items",
      icon: <FilesIcon />,
    },
    // Here for future use
    // {
    //   title: "Services",
    //   module: "services",
    //   icon: <ServiceIcon />,
    // },
    // {
    //   title: "Proposals",
    //   module: "proposals",
    //   icon: <ProposalIcon />,
    // },
    // {
    //   title: "Contracts",
    //   module: "contract-template",
    //   icon: <ContractIcon />,
    // },
    // {
    //   title: "Forms",
    //   module: "forms",
    //   icon: <FormTemplateIcon />,
    // },
    // {
    //   title: "Form Sections",
    //   module: "form-sections",
    //   icon: <FormSectionIcon />,
    // },
    // {
    //   title: "Form Fields",
    //   module: "form-fields",
    //   icon: <FormFieldIcon />,
    // },
    // {
    //   title: "Form Field Options",
    //   module: "form-field-options",
    //   icon: <FormFieldOptionIcon />,
    // },
    // {
    //   title: "Screen Recordings",
    //   module: "screen-recordings",
    //   icon: <ScreenRecordingIcon />,
    // },
    !siteTemplate && {
      title: "Plugins",
      module: "plugins",
      icon: <PluginIcon />,
    },
    !siteTemplate && {
      title: "Plugin Lists",
      module: "Plugin-lists",
      icon: <PluginListIcon />,
    },
  ];
  return compact(menu);
}
