import { LoadingCard } from "./LoadingListCards";
import SecondaryHeading from "./SecondaryHeading";

export default function ModuleLimitDetail({
  isLoading,
  title,
  description,
  progress,
}) {
  return (
    <>
      <div className="w-full">
        <SecondaryHeading>{title}</SecondaryHeading>
        {isLoading ? (
          <LoadingCard className="h-2 mt-4" />
        ) : (
          description && <p className="body-text m-0 text-11">{description}</p>
        )}
        {isLoading ? (
          <LoadingCard className="h-2 mt-3" />
        ) : (
          <div className="site-bar">
            <div style={{ width: `${progress >= 100 ? 100 : progress%100}%` }}></div>
          </div>
        )}
      </div>
    </>
  );
}
