import { createSlice } from "@reduxjs/toolkit";
import { isEqual } from "helpers";

const initialState = {
  members: [],
  loaded: false,

  defaultState: {
    members: [],
    loaded: false,
    website_id: "",
  },
};

const membersSlice = createSlice({
  name: "members",
  initialState,
  reducers: {
    getMembers: (state, action) => {
      state.members = action.payload;
      state.loaded = true;
    },
    addNewMember: (state, action) => {
      state.members.push(action.payload);
    },
    unuploadMembers: (state, action) => {
      state.loaded = false;
    },
    updateSingleMember: (state, action) => {
      const { single_site_id } = action.payload;
      if (!single_site_id) {
        const index = state.members.findIndex((_) =>
          isEqual(_.id, action.payload.id)
        );
        if (!isEqual(index, -1)) {
          for (const key in action.payload) {
            state.members[index][key] = action.payload[key];
          }
        }
        const defaultStateIndex = state.defaultState.members.findIndex((_) =>
          isEqual(_.id, action.payload.id)
        );
        if (!isEqual(defaultStateIndex, -1)) {
          for (const key in action.payload) {
            state.defaultState.members[defaultStateIndex][key] =
              action.payload[key];
          }
        } else {
          state.defaultState.members.push(state.members[index]);
        }
      } else if (single_site_id === state.defaultState.website_id) {
        const defaultStateIndex = state.defaultState.members.findIndex((_) =>
          isEqual(_.id, action.payload.id)
        );
        if (!isEqual(defaultStateIndex, -1)) {
          for (const key in action.payload) {
            state.defaultState.members[defaultStateIndex][key] =
              action.payload[key];
          }
        } else {
          state.defaultState.members.push(action.payload);
        }
      }
    },
    deleteSingleMember: (state, action) => {
      state.members = state.members.filter(
        (_) => !isEqual(_.id, action.payload)
      );
    },
    getAllDefaultMembers: (state, action) => {
      const { website_id, data } = action.payload;
      state.defaultState.members = data;
      state.defaultState.website_id = website_id;
      state.defaultState.loaded = true;
    },
    addNewDefaultMember: (state, action) => {
      const index = state.members.findIndex((_) =>
        isEqual(_.id, action.payload.id)
      );
      if (!isEqual(index, -1)) {
        state.members[index].default_tem = "1";
        state.defaultState.members.push(state.members[index]);
      }
    },
    removeSingleDefaultMember: (state, action) => {
      const index = state.members.findIndex((_) =>
        isEqual(_.id, action.payload.id)
      );
      if (!isEqual(index, -1)) {
        state.members[index].default_tem = "0";
      }
      state.defaultState.members = state.defaultState.members.filter(
        (_) => !isEqual(_.id, action.payload.id)
      );
    },
    unLoadDefaultMembers: (state, action) => {
      state.defaultState.loaded = false;
    },
  },
});

export const {
  getMembers,
  addNewMember,
  unuploadMembers,
  updateSingleMember,
  deleteSingleMember,
  getAllDefaultMembers,
  addNewDefaultMember,
  removeSingleDefaultMember,
  unLoadDefaultMembers,
} = membersSlice.actions;

export default membersSlice.reducer;
