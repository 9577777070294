import { Route, Routes } from "react-router-dom";
import Library from "./Library";
import ApprovalSettings from "./Approvals/ApprovalSettings";
import BrandingAndColor from "./BrandingAndColor";
import Environments from "./Environments";
import GeneralSettings from "./GeneralSettings";
import Notifications from "./Notifications/Index";
import PriorityTab from "./Priority";
import PrioritySetting from "./Priority/PrioritySetting";
import RolesTab from "./Roles";
import RoleSetting from "./Roles/RoleSetting";
import StatusTab from "./Status/Index";
import StatusSetting from "./Status/StatusSetting";
import Tags from "./Tags";
import TagSetting from "./Tags/TagSetting";
import UserTab from "./Users";
import Groups from "./Groups";
import AddEditGroup from "./Groups/AddEditGroup";
import StatusOptions from "./Status/StatusOptions";
import LaunchpadWidgets from "./LaunchpadWidgets";
import Phases from "./Phases";
import AddEditPhase from "./Phases/AddEditPhase";
import SMTP from "./SMTP";
import Interface from "./Interface";
import Maintenance from "./Maintenance";
import SiteSetting from "./Advanced";
import { useSearch } from "helpers";
import AddEditMember from "usersModule/Members/AddEditMember";

export default function SiteSettingRoutes() {
  const { web = null } = useSearch();
  return (
    <>
      <Routes>
        <Route exact path={`/`}>
          <Route
            exact
            index
            element={web ? <GeneralSettings /> : <UserTab />}
          />
        </Route>
        <Route exact path={`/general-settings`}>
          <Route exact index element={<GeneralSettings />} />
        </Route>
        {/* Here for future use */}
        {/* <Route exact path={`/api-key`}>
          <Route exact index element={<ApiKey />} />
        </Route> */}
        <Route exact path={`/roles`}>
          <Route exact index element={<RolesTab />} />
          <Route exact path="/roles/:id" element={<RoleSetting />} />
        </Route>
        <Route exact path={`/users`}>
          <Route exact index element={<UserTab />} />
          <Route
            exact
            path="/users/:id"
            element={<AddEditMember isSiteModule />}
          />
        </Route>
        {/* Here for future use */}
        {/* <Route exact path={`/email-templates`}>
          <Route exact index element={<EmailTemplate />} />
          <Route exact path="/email-templates/:id" element={<EmailSetting />} />
        </Route> */}
        <Route exact path={"/phases"}>
          <Route exact index element={<Phases />} />
          <Route exact path="/phases/:id" element={<AddEditPhase />} />
        </Route>
        <Route exact path={`/notifications`}>
          <Route exact index element={<Notifications />} />
        </Route>
        <Route exact path={`/status`}>
          <Route exact index element={<StatusTab />} />
          <Route exact path="/status/:option" element={<StatusOptions />} />
          <Route exact path="/status/:option/:id" element={<StatusSetting />} />
        </Route>
        <Route exact path={`/priority`}>
          <Route exact index element={<PriorityTab />} />
          <Route exact path="/priority/:id" element={<PrioritySetting />} />
        </Route>
        <Route exact path={`/groups`}>
          <Route exact index element={<Groups />} />
          <Route exact path="/groups/:id" element={<AddEditGroup />} />
        </Route>
        <Route exact path={`/tags`}>
          <Route exact index element={<Tags />} />
          <Route exact path="/tags/:id" element={<TagSetting />} />
        </Route>
        <Route exact path={`/smtp`}>
          <Route exact index element={<SMTP />} />
        </Route>
        <Route exact path={`/launchpad-widgets`}>
          <Route exact index element={<LaunchpadWidgets />} />
        </Route>
        <Route exact path={"approvals"}>
          <Route exact index element={<ApprovalSettings />} />
        </Route>
        <Route exact path={"environments"}>
          <Route exact index element={<Environments />} />
        </Route>
        <Route exact path={"branding-and-colors"}>
          <Route exact index element={<BrandingAndColor />} />
        </Route>
        <Route exact path={"/interface"}>
          <Route exact index element={<Interface />} />
        </Route>
        <Route exact path={"/maintenance"}>
          <Route exact index element={<Maintenance />} />
        </Route>
        <Route exact path={"/advanced"}>
          <Route exact index element={<SiteSetting />} />
        </Route>
        <Route exact path={"/library-item/*"} element={<Library />} />
      </Routes>
    </>
  );
}
