import { Link } from "react-router-dom";
import {
  SiteIcon,
  UserIcon,
  CloudIcon,
  LogoutIcon,
  BillingIcon,
  SupportIcon,
  FormFieldIcon,
} from "allIcons";
import "./header.css";
import { useState } from "react";
import { Loader, LoadingCard, PopOver, SecondaryHeading } from "commonUI";
import { isEqual, usePathname } from "helpers";
import { useUserDetails, useUserLogout, useUserPermissions } from "hooks/user";
import useUserPlan from "hooks/plan";

export default function Header() {
  const [showDrop, setShowDrop] = useState(false);
  const [module] = usePathname();
  const { view_templates, view_sites } = useUserPermissions();
  const { data: wpslPlan = {}, isLoading: loadingPlans } = useUserPlan();
  const isLibraryTemplateAllowed = isEqual(wpslPlan?.temp_access, 1);

  const { isLoading, data: userDetails, error } = useUserDetails();

  const mutation = useUserLogout();

  return (
    <>
      {mutation.isLoading ? <Loader /> : ""}
      {isLoading ? <Loader className="bg-white" /> : ""}
      <div className="main-header">
        <Link to="/" className="flex" aria-label="WP SiteLauncher">
          <img
            src={require("assets/header-logo.svg").default}
            width="220px"
            height="35px"
            alt="WP SiteLauncher Logo"
          />
        </Link>
        <div className="header-left-container">
          {view_sites ? (
            <Link to={"sites"} title="Sites" className="cursor-pointer">
              <div
                className={`header-icon-container ${
                  ["sites", "", "site-settings"].includes(module)
                    ? "bg-theme-blue"
                    : ""
                }`}
              >
                <SiteIcon />
              </div>
            </Link>
          ) : (
            ""
          )}
          <Link to={"users"} title="Users" className="cursor-pointer">
            <div
              className={`header-icon-container ${
                module === "users" ? "bg-theme-blue" : ""
              }`}
            >
              <UserIcon />
            </div>
          </Link>
          {view_templates && isLibraryTemplateAllowed && !loadingPlans ? (
            <Link
              to={"/template-library"}
              title="Template Library"
              className="cursor-pointer"
            >
              <div
                className={`header-icon-container
            ${module === "template-library" ? "bg-theme-blue" : ""}
            `}
              >
                <CloudIcon />
              </div>
            </Link>
          ) : (
            ""
          )}
          <div className="relative">
            <div
              className={`${showDrop ? "dropdown-click-block" : "hidden"}`}
            />
            <div
              className="flex items-center cursor-pointer ml-1 relative"
              onClick={() => {
                setShowDrop((pre) => !pre);
              }}
            >
              <div className="header-icon-container">
                <div className="header-filled-icon-container rounded-full">
                  <img src={require("assets/pic.png")} alt="user avatar" />
                </div>
              </div>
              <div className="header-user-container">
                {isLoading ? (
                  <LoadingCard className="h-2 w-14" />
                ) : (
                  <SecondaryHeading className="text-white font-bold ">
                    {userDetails?.user_name ?? userDetails?.name}
                  </SecondaryHeading>
                )}
              </div>
              <FormFieldIcon />
              <PopOver
                open={showDrop}
                onClose={() => {
                  setShowDrop((pre) => false);
                }}
                toolTip={{ right: "20px", vertical: "top" }}
                style={{ top: "calc(100% + 7px)", right: "20px" }}
              >
                <div className=" header-user-dropdown">
                  <a
                    href="https://wpsitelauncher.com/help"
                    target="_blank"
                    rel="noreferrer"
                    className="header-dropdown-content"
                  >
                    <SupportIcon />
                    <span className="body-text">Help Center & Support</span>
                  </a>
                  <a
                    className="header-dropdown-content"
                    href="https://wpsitelauncher.com/customer-dashboard/?action=index&model=subscription"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <BillingIcon />
                    <span className="body-text">Manage Plan & Billing</span>
                  </a>
                  <div
                    className="header-dropdown-content cursor-pointer"
                    onClick={() => mutation.mutate()}
                  >
                    <LogoutIcon />
                    <span className="body-text">Logout</span>
                  </div>
                </div>
              </PopOver>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
