import { useQuery, useMutation } from "@tanstack/react-query";
import { useEffect } from "react";
import { axiosInstance, printError, useUtilities } from "helpers";
import {
  addSingleCompany,
  getAllCompanies,
  updateSingleCompany,
  deleteSingleCompany,
} from "redux/reducers/usersModule/companies";
import {
  unLoadDefaultMembers,
  unuploadMembers,
} from "redux/reducers/usersModule/members";
import { queryClient } from "client";

async function getCompanies() {
  return axiosInstance
    .get("/allCompanies", {
      params: {
        apiKey: localStorage.getItem("apikey"),
        added_by: localStorage.getItem("user_id"),
      },
    })
    .then((res) => res.data)
    .catch((err) => new Error(err));
}

function useGetCompanies() {
  return useQuery(["companies"], getCompanies);
}

export function useCompanies() {
  const { data, isLoading, error } = useGetCompanies();
  const {
    dispatch,
    companies: { companies, loaded },
  } = useUtilities();

  useEffect(() => {
    if (isLoading || loaded) return;
    if (data) dispatch(getAllCompanies(data?.Data || []));
  }, [data, isLoading, loaded]);
  return {
    data: companies,
    isLoading: isLoading || !loaded,
    error,
  };
}

async function addCompany(company) {
  return axiosInstance
    .post("addCompany", {
      apiKey: localStorage.getItem("apikey"),
      added_by: localStorage.getItem("user_id"),
      ...company,
    })
    .then((res) => res.data)
    .catch((err) => new Error(err));
}

export function useAddCompany(redirect = "") {
  const { dispatch, navigate, notification } = useUtilities();
  return useMutation(addCompany, {
    onSuccess: (data) => {
      if (data.StatusCode === 200) {
        dispatch(addSingleCompany(data.Data));
        notification({
          type: "success",
          message: "Company added successfully",
        });
        if (redirect) navigate(redirect + data.Data.id);
      } else {
        notification({
          type: "error",
          message: printError(data.message),
        });
      }
    },
    onError: (err) => {
      notification({
        type: "error",
        message: printError(err.message),
      });
    },
  });
}

async function updateCompany(company) {
  return axiosInstance
    .post("updateCompany", {
      apiKey: localStorage.getItem("apikey"),
      added_by: localStorage.getItem("user_id"),
      ...company,
    })
    .then((res) => res.data)
    .catch((err) => new Error(err));
}

export function useUpdateCompany() {
  const { dispatch, notification } = useUtilities();
  return useMutation(updateCompany, {
    onSuccess: (data) => {
      if (data.StatusCode === 200) {
        dispatch(updateSingleCompany(data.Data));
        notification({
          type: "success",
          message: "Company updated successfully",
        });
        queryClient.invalidateQueries(["siterelatedusers"]);
        dispatch(unLoadDefaultMembers());
        queryClient.invalidateQueries(["members"]);
        dispatch(unuploadMembers());
      } else {
        notification({
          type: "error",
          message: printError(data.message || data.Message),
        });
      }
    },
    onError: (err) => {
      notification({
        type: "error",
        message: printError(err.message),
      });
    },
  });
}

async function deleteCompany(id) {
  return axiosInstance
    .post("delCompany", {
      apiKey: localStorage.getItem("apikey"),
      company_id: id,
    })
    .then((res) => ({ ...res.data, id }))
    .catch((err) => new Error(err));
}

export function useDeleteCompany(redirect = "") {
  const { dispatch, notification, navigate } = useUtilities();
  return useMutation(deleteCompany, {
    onSuccess: (data) => {
      if (data.StatusCode === 200) {
        dispatch(deleteSingleCompany(data.id));
        notification({
          type: "success",
          message: "Company deleted successfully",
        });
        if (redirect) navigate(redirect);
      } else {
        notification({
          type: "error",
          message: printError(data.message || data.Message),
        });
      }
    },
    onError: (err) => {
      dispatch({
        type: "error",
        message: printError(err.message),
      });
    },
  });
}
